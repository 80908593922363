import Checkbox from '../ui/Checkbox'
import Input from '../ui/Input'
import * as styles from '../../misc/styles';

interface LungProps {
    v0: number;
    setV0: Function;
    resistance: number;
    setResistance: Function;
    compliance: number;
    setCompliance: Function;
    spontaneous: boolean;
    setSpontaneous: Function;
    pmus: number;
    setPmus: Function;
    nti: number;
    setNti: Function;
    nShift: number;
    setnShift: Function
}

/**
 * Component that displays, and allows user to change lung parameters
 */
const Lung: React.FC<LungProps> = ({ v0, setV0, resistance, setResistance, compliance, setCompliance, spontaneous, setSpontaneous, pmus, setPmus, nti, setNti, nShift, setnShift }) => {
    const toggleSpontaneous = () => setSpontaneous(!spontaneous);

    return (
        <div className={'ventsim-lung ' + styles.graycard}>
            <Input label="V0 (L)" placeholder={v0} onChange={setV0} />
            <Input
                label="R (cmH2O/L/sec)"
                placeholder={resistance}
                onChange={setResistance}
            />
            <Input
                label="C (L/cmH2O)"
                placeholder={compliance}
                onChange={setCompliance}
            />
            <Checkbox
                label="Spontaneous"
                isOn={spontaneous}
                toggle={toggleSpontaneous}
            />
            <Input
                disabled={!spontaneous}
                label="Pmus (cmH2O)"
                placeholder={pmus}
                onChange={setPmus}
            />
            <Input
                disabled={!spontaneous}
                label="Neural Ti (sec)"
                placeholder={nti}
                onChange={setNti}
            />
            <Input
                disabled={!spontaneous}
                label="Neural Shift (sec)"
                placeholder={nShift}
                onChange={setnShift}
            />
        </div>
    )
}

export default Lung;