import React from "react";
const row = "flex";
const colLabel = "p-1";
const colInput = "p-1 text-center";

type HCheckboxProps = {
  label?: string;
  isOn?: boolean;
  toggle?: React.ChangeEventHandler;
};

export default function HCheckbox({ label, isOn, toggle }: HCheckboxProps) {
  return (
    <div className={row}>
      <label className={colLabel}>{label}</label>
      <div className={colInput}>
        <input
          onChange={toggle}
          checked={isOn}
          className="react-switch-checkbox"
          id={"react-switch-new"}
          type="checkbox"
        />
      </div>
    </div>
  );
}
