import React from 'react';
import SimVC from './SimVC'
import SimPC from './SimPC';

function CompareVCPC() {
    return (
        <div className={"grid grid-cols-2"}>
            <SimVC widthFactor={2} />
            <SimPC widthFactor={2} />
        </div>
    );
}

export default CompareVCPC;