import React from 'react';
import SimVC from './SimVC'

function Compare2VC() {
    return (
        <div className={"grid grid-cols-2"}>
            <SimVC widthFactor={2} />
            <SimVC widthFactor={2} />
        </div>
    );
}

export default Compare2VC;
