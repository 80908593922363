import React from 'react';

type CardProps = {
    imgSrc?: string;
    svg?: JSX.Element;
    title?: JSX.Element;
    tail?: JSX.Element;
    children?: Object
};

function Card({ imgSrc, svg, title, tail, children }: CardProps) {

    return (
        <div className="m-5 flex justify-center items-center">
            <div className="max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl bg-gray-200  p-6 rounded-md tracking-wide">
                <div className="flex items-center mb-4">
                    {imgSrc ? <img alt="avatar" className="card-image w-20 rounded-full border-1 border-gray-500" src={imgSrc} /> : <></>}
                    {svg ? svg : <></>}
                    <div className="card-title leading-5 ml-6">
                        {title}
                    </div>
                </div>
                <div className="card-content">
                    {children}
                </div>
                <div id="card-tail">
                    {tail}
                </div>
            </div>
        </div>
    );
}

export default Card;