export default function ACPC(
  duration = 10,
  n = 500,
  pi = 15,
  ti = 1,
  rr = 10,
  peep = 5,
  r = 10,
  c = 0.05,
  v0 = 0,
  mPmus = 2,
  nTi = 0.8,
  nShift = -0.1
) {
  const given = { duration: duration, n: n };
  const vent = { pi: pi, ti: ti, rr: rr, peep: peep };
  const lung = { resistance: r, compliance: c, v0: v0, mPmus: mPmus, nTi: nTi, nShift: nShift };
  // computed variables
  const breathTime = 60.0 / vent.rr;
  const dt = given.duration / given.n;

  const ticks = Array.from(Array(given.n).keys());
  const Time = ticks.map(t => (t * given.duration) / given.n);
  const Volume = Array(given.n);
  const Flow = Array(given.n);
  const Palv = Array(given.n);
  const timeInCycle = Time.map(t => t % breathTime);
  const phaseInCycle = timeInCycle.map(t => (t < vent.ti ? "INSP" : "EXP"));

  // calculate pmus
  const lungTimeInCycle = Time.map(t => (t - lung.nShift) % breathTime);
  const lungPhaseInCycle = lungTimeInCycle.map(t => (t < lung.nTi ? "INSP" : "EXP"));
  const lungInsp = lungPhaseInCycle.map(p => (p === "INSP" ? 1 : 0));
  const PmusTemp = ticks.map(
    i => (-4 * lungInsp[i] * lung.mPmus * lungTimeInCycle[i] * (lung.nTi - lungTimeInCycle[i])) / lung.nTi ** 2);
  const Pmus = PmusTemp.map(p => (p > 0 ? 0 : p));
  const PmusPlusPEEP = Pmus.map(p => p + vent.peep);

  // compute other variables
  const Paw = phaseInCycle.map(i => i === "INSP" ? vent.pi + vent.peep : vent.peep);
  Volume[0] = lung.v0;
  for (const t of ticks) {
    Palv[t] = Volume[t] / lung.compliance + vent.peep ;
    Flow[t] = (Paw[t] - Palv[t] - Pmus[t]) / lung.resistance;
    Volume[t + 1] = Volume[t] + Flow[t] * dt;
  }
  return ticks.map(t =>
    Object({
      Time: Time[t] + dt,
      Paw: Paw[t],
      Palv: Palv[t],
      Pmus: Pmus[t],
      Flow: Flow[t] * 60,
      Volume: Volume[t] * 1000,
      PmusPlusPEEP: PmusPlusPEEP[t]
    })
  );
}
