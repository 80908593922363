import React from 'react';
import SimVC from './SimVC'
import SimVT from './SimVT';

function CompareVCVT() {
    return (
        <div className={"grid grid-cols-2"}>
            <SimVC widthFactor={2} />
            <SimVT widthFactor={2} />
        </div>
    );
}

export default CompareVCVT;