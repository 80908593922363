import React, { ChangeEventHandler } from "react";

const row = "flex m-1";
const colLabel = "w-2/3";
const colInput = "w-1/3 text-center";

type CheckboxProps = {
  label?: string,
  isOn?: boolean,
  toggle: ChangeEventHandler,
};

export default function Checkbox({ label, isOn, toggle }: CheckboxProps) {
  return (
    <div className={row}>
      <label className={colLabel}>{label}</label>
      <div className={colInput}>
        <input
          onChange={toggle}
          checked={isOn}
          className="react-switch-checkbox"
          id={"react-switch-new"}
          type="checkbox"
        />
      </div>
    </div>
  );
}
