import React from 'react'
import Checkbox from '../ui/Checkbox'
import Input from '../ui/Input'
import * as styles from '../../misc/styles';

interface VentProps {
    mode: 'vc' | 'pc' | 'vt';
    rr: number;
    setRr: Function;
    peep: number;
    setPeep: Function;
    tv?: number;
    setTv?: Function;
    flow?: number;
    setFlow?: Function;
    sqWave?: boolean;
    setSqWave?: Function;
    pi?: number;
    setPi?: Function;
    ti?: number;
    setTi?: Function
}

/**
 * Component that displays, and allows user to change ventilator parameters
 */
const Vent: React.FC<VentProps> = ({ mode, rr, setRr, tv, setTv, flow, setFlow, peep, setPeep, sqWave, setSqWave, pi, setPi, ti, setTi }) => {
    const toggleWave = () => { if (setSqWave) setSqWave(!sqWave); };
    return (
        <div className={'ventsim-vent ' + styles.graycard}>
            {/* Common Settings */}
            <span>
                <Input label="RR (1/min)" placeholder={rr} onChange={setRr} />
                <Input label="PEEP (cmH2O)" placeholder={peep} onChange={setPeep} />
            </span>

            {/* VC Settings */}
            {(mode === 'vc' && setTv && setFlow) ?
                <span>
                    <Input label="TV (L)" placeholder={tv} onChange={setTv} />
                    <Input label="Flow (L/min)" placeholder={flow} onChange={setFlow} />
                    <Checkbox label="Sq Ramp" isOn={sqWave} toggle={toggleWave} />
                </span> : <></>}

            {/* PC Settings */}
            {(mode === 'pc' && setPi && setTi) ?
                <span>
                    <Input label="Pi (cmH2O)" placeholder={pi} onChange={setPi} />
                    <Input label="Ti (sec)" placeholder={ti} onChange={setTi} />
                </span> : <></>}

            {/* VT Settings */}
            {(mode === 'vt' && setTv && setTi) ?
                <span>
                    <Input label="TV (L)" placeholder={tv} onChange={setTv} />
                    <Input label="Ti (sec)" placeholder={ti} onChange={setTi} />
                </span> : <></>}
        </div>
    )
}

export default Vent;