import React from 'react';
import SimVT from './SimVT'
import SimPC from './SimPC';

export default function ComparePCVT() {
    return (
        <div className={"grid grid-cols-2"}>
            <SimPC widthFactor={2} />
            <SimVT widthFactor={2} />
        </div>
    );
}