import React from 'react';
import SimPC from './SimPC'

function Compare2PC() {
    return (
        <div className={"grid grid-cols-2"}>
            <SimPC widthFactor={2} />
            <SimPC widthFactor={2} />
        </div>
    );
}

export default Compare2PC;