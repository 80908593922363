// This file defines prop types for the waveform and loop charts
// It also defines helper function that format the labels of the charts

/**
 * Prop type for waveform and loop components
 */
export interface ChartProps {
    df: Array<any>;
    x: string;
    ys: Array<string>;
    strokeWidth?: number;
    colors?: Array<string>;
    autoScale?: boolean;
    xMin?: number;
    xMax?: number;
    yMin?: number;
    yMax?: number;
    ncol?: number;
    nrow?: number
}

// The following functions are passed to recharts to generate labels for the variables being plotted
/**
 * Takes a variable value, and name, and returns the value
 * rounded and the unit attached to it as a tuple.
 * @example formatterY(5, 'Paw') = [5, ' (cmH2O)']
 */
export function formatterY(value: number, name: string) {
    let unit = getUnit(name);
    let rounded = Math.round(value * 100) / 100;
    return ([rounded, name + unit])
}

/**
 * Takes a variable value, and name, and returns the value
 * rounded and the unit attached to it as a string.
 * @example formatterX(5, 'Time') = 'Time (sec): 5'
 */
export function formatterX(value: number, name: string) {
    return `${name} ${getUnit(name)} : ${Math.round(value * 100) / 100}`
}

function getUnit(label: string) {
    let unit = "";
    if (label.startsWith("P")) unit = " (cmH2O)";
    else if (label === "Flow") unit = " (L/min)";
    else if (label === "Volume") unit = " (mL)";
    else if (label === "Time") unit = " (sec)";
    return unit;
}