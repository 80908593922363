import React from 'react';
import Card from '../components/ui/Card';
import UserInfo from '../components/UserInfo';
import { UserContext } from '../contexts/User';
import SelfAddUser from './SelfAddUser';

const title = <h1 className="font-bold text-lg">Accessing VentSim</h1>
const title_authenticate = <h1 className="font-bold text-lg">Authenticate</h1>

const signinneeded = (
    <div>
        <p className="mb-5">
            Please note that these modules serve purely as illustrative tools, complementing your learning experience.
            While they do not substitute expert medication judgment or medical care,
            we encourage you to explore these valuable resources and enhance your knowledge in the field.</p>
        <p className="mb-5">
            To access to our website, we kindly request you to sign up. As part of ensuring a secure authentication process,
            our app utilizes Google's authentication services to confirm your identity.
            We never ask for nor do we have access to your password. Upon successful authentication via Google,
            we would appreciate it if you could provide us with your name, institution, and title to complete the sign-up procedure. </p>
        <p className="mb-5">
            Finally, by signing up for this website or app, you gain complimentary access to our ventilator simulation modules, and you agree to anonymously share your usage data.
            The data will be used in research focused on medication education. We also may ask you to take periodic surveys, and we sincerely value your participation in them.
            Be sure to review the comprehensive details outlined in our
            <a className="text-blue-600" target="_blank" rel="noopener noreferrer" href="https://ccsimmods.firebaseapp.com/terms-conditions.html"> Terms and Conditions </a> and
            <a className="text-blue-600" target="_blank" rel="noopener noreferrer" href="https://ccsimmods.firebaseapp.com/privacy-policy.html"> Privacy Policy </a>
            for further information.</p>
    </div>);

const success = (
    <div>
        <p className="mb-5">Sign in was successful. You can now use the app.</p>
    </div>);

const loginsvg = (<svg width="54px" height="54px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-in-alt"
    className="svg-inline--fa fa-sign-in-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"><path fill="#644C63" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z">
    </path></svg>);

const svg_authenticate = <svg width="54px" height="54px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="authenticate-alt" viewBox="0 0 24 24" className="svg-inline--fa fa-sign-in-alt fa-w-16" xmlns="http://www.w3.org/2000/svg">
    <path fill="#644C63" d="M10.7905 15.17C10.5905 15.17 10.4005 15.09 10.2605 14.95L7.84055 12.53C7.55055 12.24 7.55055 11.76 7.84055 11.47C8.13055 11.18 8.61055 11.18 8.90055 11.47L10.7905 13.36L15.0905 9.06003C15.3805 8.77003 15.8605 8.77003 16.1505 9.06003C16.4405 9.35003 16.4405 9.83003 16.1505 10.12L11.3205 14.95C11.1805 15.09 10.9905 15.17 10.7905 15.17Z"/>
    <path fill="#644C63" d="M12.0009 22.75C11.3709 22.75 10.7409 22.54 10.2509 22.12L8.67086 20.76C8.51086 20.62 8.11086 20.48 7.90086 20.48H6.18086C4.70086 20.48 3.50086 19.28 3.50086 17.8V16.09C3.50086 15.88 3.36086 15.49 3.22086 15.33L1.87086 13.74C1.05086 12.77 1.05086 11.24 1.87086 10.27L3.22086 8.68C3.36086 8.52 3.50086 8.13 3.50086 7.92V6.2C3.50086 4.72 4.70086 3.52 6.18086 3.52H7.91086C8.12086 3.52 8.52086 3.37 8.68086 3.24L10.2609 1.88C11.2409 1.04 12.7709 1.04 13.7509 1.88L15.3309 3.24C15.4909 3.38 15.8909 3.52 16.1009 3.52H17.8009C19.2809 3.52 20.4809 4.72 20.4809 6.2V7.9C20.4809 8.11 20.6309 8.51 20.7709 8.67L22.1309 10.25C22.9709 11.23 22.9709 12.76 22.1309 13.74L20.7709 15.32C20.6309 15.48 20.4809 15.88 20.4809 16.09V17.79C20.4809 19.27 19.2809 20.47 17.8009 20.47H16.1009C15.8909 20.47 15.4909 20.62 15.3309 20.75L13.7509 22.11C13.2609 22.54 12.6309 22.75 12.0009 22.75ZM6.18086 5.02C5.53086 5.02 5.00086 5.55 5.00086 6.2V7.91C5.00086 8.48 4.73086 9.21 4.36086 9.64L3.01086 11.23C2.66086 11.64 2.66086 12.35 3.01086 12.76L4.36086 14.35C4.73086 14.79 5.00086 15.51 5.00086 16.08V17.79C5.00086 18.44 5.53086 18.97 6.18086 18.97H7.91086C8.49086 18.97 9.22086 19.24 9.66086 19.62L11.2409 20.98C11.6509 21.33 12.3709 21.33 12.7809 20.98L14.3609 19.62C14.8009 19.25 15.5309 18.97 16.1109 18.97H17.8109C18.4609 18.97 18.9909 18.44 18.9909 17.79V16.09C18.9909 15.51 19.2609 14.78 19.6409 14.34L21.0009 12.76C21.3509 12.35 21.3509 11.63 21.0009 11.22L19.6409 9.64C19.2609 9.2 18.9909 8.47 18.9909 7.89V6.2C18.9909 5.55 18.4609 5.02 17.8109 5.02H16.1109C15.5309 5.02 14.8009 4.75 14.3609 4.37L12.7809 3.01C12.3709 2.66 11.6509 2.66 11.2409 3.01L9.66086 4.38C9.22086 4.75 8.48086 5.02 7.91086 5.02H6.18086Z"/></svg>

/**
 * Login page for the App. Takes a callback function to call when login is completed.
 * This allows the router to redirect the user back to the previous page.
 */
function Login() {
    const { user, isInvited } = React.useContext(UserContext);
    return (
        <div>
            <Card title={title} svg={loginsvg}>
                {signinneeded}
            </Card>
            <Card title={title_authenticate} svg={svg_authenticate}>
                {!user.uid ? <p className="mb-5">Kindly confirm your identity using Google's authentication services. Click the button below to sign in. </p> : <></>}
                {user.uid && !isInvited ? <p className="mb-5">Kindly add your name, institution, and title in the form below. Hit submit when you are done.</p> : <></>}
                <UserInfo />
            </Card>
            {user.uid && !isInvited ? <SelfAddUser /> : <></>}
            {user.uid && isInvited ? <Card>{success}</Card> : <></>}
        </div>
    );
}

export default Login;