import React from 'react';
import { UserContext } from '../contexts/User';
import { useHistory, useLocation } from 'react-router';
import { Routes } from '../components/Router';
import LoginButton from './ui/LoginButton';

/**
 * UserInfo shows the user name (when user is logged in) and sign in/out button
 */
export default function UserInfo() {
    // Pull user information
    const { user } = React.useContext(UserContext);

    // Pull route information to use to redirect route once user is signed in
    const routeLocation = useLocation();
    const routeHistory = useHistory();
    const callback = () => {
        //@ts-ignore
        const { from } = routeLocation.state || { from: { pathname: Routes.home } };
        routeHistory.replace(from);
    }

    return (
        <div>
            {user && user.uid ? <SignOut /> : <SignIn callback={callback} />}
        </div>

    );
}

type SignInProps = { callback?: Function }

function SignIn({ callback }: SignInProps) {
    const { signIn } = React.useContext(UserContext);
    return (
        <LoginButton onClick={() => signIn(callback)}>
            Sign In
        </LoginButton>
    );
}

function SignOut() {
    const { user, signOut } = React.useContext(UserContext);
    const { email, displayName } = user && user.uid ? user : { email: '', displayName: '' };
    const routeHistory = useHistory();
    return (
        <div className="flex">
            <div className="px-2 hidden lg:block">{displayName ? displayName : email}</div>
            <LoginButton onClick={() => signOut(() => routeHistory.push("/"))}>
                Sign Out
            </LoginButton>
        </div>);
}