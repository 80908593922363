import React from "react";

import {
  LineChart,
  Tooltip,
  Legend,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

import { ChartProps, formatterX, formatterY } from './Chart';

/**
 * Generate PV or FV loop corresponding to the data provided.
 */
const Loop: React.FC<ChartProps> = (
  { df, x, ys, strokeWidth, colors, autoScale = true, xMin = 0, xMax = 0, yMin = 0, yMax = 0, ncol = 2.0, nrow = 2.0 }) => {
  // Chart scales
  const xDomain = autoScale ? ['auto', 'auto'] : [Math.round(xMin), Math.round(xMax)];
  const yDomain = autoScale ? ['auto', 'auto'] : [Math.round(yMin), Math.round(yMax)];
  const ysUpdatePmus = ys.map(y => y === "Pmus" ? "PmusPlusPEEP" : y);
  const margins = { top: 1, right: 1, left: 1, bottom: 1 };

  // Chart width and height changes based on browser's dimension
  const widthFactor = 1.05;
  const heightFactor = 1.1;
  const [width, setWidth] = React.useState(window.innerWidth / widthFactor / ncol);
  const [height, setHeight] = React.useState(window.outerHeight / heightFactor / nrow);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth / widthFactor / ncol);
      setHeight(window.outerHeight / heightFactor / nrow);
    })
  }, [ncol, nrow]);

  return (
    <div className={"ventsim-loop"}>
      <LineChart width={width} height={height} data={df} margin={margins} layout={'vertical'} syncId="loop">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" domain={yDomain} />
        <YAxis dataKey={x} type="number" reversed={true} domain={xDomain} />
        <Tooltip formatter={formatterY} labelFormatter={(value: number) => formatterX(value, x)} />
        <Legend align="right" verticalAlign="top" height={1} />
        {ysUpdatePmus.map((y, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={y}
            stroke={colors ? colors[index] : 'black'}
            dot={false}
            strokeWidth={strokeWidth}
          />
        ))}
      </LineChart>
    </div>
  );
}

export default Loop;