import React from 'react';

/**
 * This page pulls the old workshop page as an iframe
 */
export default function Workshop() {

  return (
    <iframe className="w-screen h-screen" style={ {overflow: 'hidden'}}
      src="https://ccsimmods.firebaseapp.com/workshop/"
      title="Mechanical Ventilator Workshop"></iframe>
  );
}