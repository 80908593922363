export function shuffle(a: any[]) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}

export const colors = [
    "IndianRed", "Crimson", "PaleVioletRed", "Tomato", "DarkKhaki", "Gold", "Plum", "SlateBlue", "MediumSeaGreen", "Olive", "Teal", "SteelBlue", "MediumSlateBlue", "BurlyWood", "Chocolate", "Brown", "Maroon", "DarkSlateGray", "DimGray"
];

export const colors1 = shuffle([
    "Crimson", "PaleVioletRed", "DarkKhaki", "Gold", "SlateBlue", "MediumSeaGreen", "Teal", "DarkSlateGray"
]);

export const colors2 = shuffle([
    "IndianRed", "Tomato", "SteelBlue", "Chocolate", "Brown"
]);

export const colors3 = shuffle([
    "Plum", "Olive", "MediumSlateBlue", "BurlyWood", "Maroon", "DimGray"
]);
