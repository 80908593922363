import React from 'react';

/**
 * This page pulls the old simulator as an iframe
 */
export default function OtherPS() {
    return (
        <iframe id="embeddedsims" className="ventsim-iframe w-screen h-screen" style={{ overflow: 'hidden' }}
            src="https://ccsimmods.firebaseapp.com/mechanicalventilation/?mode=PS"
            title="Mechanical Ventilator Simulator - PS"></iframe>
    );
}