import React from 'react';
import { HashRouter, Switch, Route } from "react-router-dom";
import { SettingsContext } from '../contexts/Settings';
import Analytics from '../screens/Analytics';
import Compare2PC from "../scenarios/Compare2PC";
import Compare2VC from "../scenarios/Compare2VC";
import CompareVCPC from "../scenarios/CompareVCPC";
import CompareVCPCVT from '../scenarios/CompareVCPCVT';
import Home from "../screens/Home";
import Login from "../screens/Login";
import LoopPC from "../scenarios/LoopPC";
import LoopVC from "../scenarios/LoopVC";
import SimPC from "../scenarios/SimPC";
import Simvt from "../scenarios/SimVT";
import SimVC from "../scenarios/SimVC";
import OpenSim from "../scenarios/OpenSim";
import PrivateRoute from './PrivateRoute';
import Workshop from '../scenarios/Workshop';
import InviteUser from '../screens/InviteUser';
import Compare2VT from '../scenarios/Compare2VT';
import ComparePCVT from '../scenarios/ComparePCVT';
import CompareVCVT from '../scenarios/CompareVCVT';
import OtherAPRV from '../scenarios/OtherAPRV';
import OtherPS from '../scenarios/OtherPS';
import OtherInflection from '../scenarios/OtherInflection';

const base = ''; // can use process.env.PUBLIC_URL for subdirectory name;

/**
 * App routes.
 */
export const Routes = {
    base: '',
    home: base + '/',
    vc: base + '/vc',
    pc: base + '/pc',
    vt: base + '/vt',
    vcloop: base + '/vcloop',
    pcloop: base + '/pcloop',
    vccompare: base + '/vccompare',
    pccompare: base + '/pccompare',
    vtcompare: base + '/vtcompare',
    vcpccompare: base + '/vcpccompare',
    pcvtcompare: base + '/pcvtcompare',
    vcvtcompare: base + '/vcvtcompare',
    vcpcvtcompare: base + '/vcpcvtcompare',
    analytics: base + '/analytics',
    login: base + '/login',
    reset: base + '/',
    opensim: base + '/opensim',
    workshop: base + '/workshop',
    inviteuser: base + '/inviteuser',
    otheraprv: base + '/aprv',
    otherps: base + '/ps',
    otherinflection: base + '/inflection',
}

/**
 * App router component
 */
export const Router: React.FC = ({ children }) => {
    const { resetSettings } = React.useContext(SettingsContext);

    return (
        <HashRouter>
            {children}
            <Switch>
                <PrivateRoute path={Routes.vc}><SimVC /></PrivateRoute>
                <PrivateRoute path={Routes.pc}><SimPC /></PrivateRoute>
                <PrivateRoute path={Routes.vt}><Simvt /></PrivateRoute>
                <PrivateRoute path={Routes.vcloop}><LoopVC /></PrivateRoute>
                <PrivateRoute path={Routes.pcloop}><LoopPC /></PrivateRoute>
                <PrivateRoute path={Routes.vccompare}><Compare2VC /></PrivateRoute>
                <PrivateRoute path={Routes.pccompare}><Compare2PC /></PrivateRoute>
                <PrivateRoute path={Routes.vcpccompare}><CompareVCPC /></PrivateRoute>
                <PrivateRoute path={Routes.vtcompare}><Compare2VT /></PrivateRoute>
                <PrivateRoute path={Routes.vcvtcompare}><CompareVCVT /></PrivateRoute>
                <PrivateRoute path={Routes.pcvtcompare}><ComparePCVT /></PrivateRoute>
                <PrivateRoute path={Routes.vcpcvtcompare}><CompareVCPCVT /></PrivateRoute>
                <PrivateRoute path={Routes.opensim}><OpenSim /></PrivateRoute>
                <PrivateRoute path={Routes.workshop}><Workshop /></PrivateRoute>
                <PrivateRoute path={Routes.analytics}><Analytics /></PrivateRoute>
                <PrivateRoute path={Routes.inviteuser}><InviteUser /></PrivateRoute>
                <PrivateRoute path={Routes.otheraprv}><OtherAPRV /></PrivateRoute>
                <PrivateRoute path={Routes.otherps}><OtherPS /></PrivateRoute>
                <PrivateRoute path={Routes.otherinflection}><OtherInflection /></PrivateRoute>

                <Route path={Routes.login}><Login /></Route>
                <Route path={Routes.reset}><Home callback={resetSettings} /></Route>
                <Route path={Routes.home}><Home /></Route>
            </Switch>
        </HashRouter >
    );
}