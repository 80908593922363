import React from 'react';
import { UserProvider } from './contexts/User';
import { Router } from './components/Router';
import { SettingsProvider } from './contexts/Settings';
import NavTop from './components/NavTop';
import SimSettings from './components/sim/SimSettings';
import UsageInfo from './components/UsageInfo';
import Tracker from './components/Tracker';

/**
 * Main component that gets loaded when app starts.
 */
function App() {
  return (
    <div className="ventsim-app">
      <UserProvider>
          <SettingsProvider>
            <Tracker>
              <Router>
                <NavTop />
                <SimSettings />
                <UsageInfo />
                {/* Rest of app components are passed as children to the Router */}
              </Router>
            </Tracker>
          </SettingsProvider>
      </UserProvider>
    </div>
  );
}

// TODO - switch debug once ready for release
export const DEBUG = false;
export const VERSION = 2;
export default App;