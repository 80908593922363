import React from 'react';
import Input from "../components/ui/Input";
import Waveform from "../components/chart/Waveform";
import Loop from "../components/chart/Loop";
import ACPC from "../logic/ACPC";
import { SettingsContext, defaultLungSettings, defaultVentSettings } from '../contexts/Settings';
import * as styles from "../misc/styles";
import { colors1, colors2, colors3 } from "../misc/colors";
import HCheckbox from '../components/ui/HCheckbox';
import Vent from '../components/sim/Vent';
import Lung from '../components/sim/Lung';

function LoopPC() {
    const [rr, setRr] = React.useState(defaultVentSettings.rr);
    const [pi, setPi] = React.useState(defaultVentSettings.pi);
    const [ti, setTi] = React.useState(defaultVentSettings.ti);
    const [peep, setPeep] = React.useState(defaultVentSettings.peep);

    // Lung
    const [resistance, setResistance] = React.useState(defaultLungSettings.resistance);
    const [compliance, setCompliance] = React.useState(defaultLungSettings.compliance);
    const [v0, setV0] = React.useState(defaultLungSettings.v0);
    const [pmus, setPmus] = React.useState(defaultLungSettings.pmus);
    const [nti, setNti] = React.useState(defaultLungSettings.nti);
    const [spontaneous, setSpontaneous] = React.useState(defaultLungSettings.spontaneous);
    const [nShift, setnShift] = React.useState(defaultLungSettings.nShift);

    // Simulation Settings
    const { duration, n, pressures, strokeWidth, autoScale, pMax, fMax, vMax } = React.useContext(SettingsContext);

    // Loop
    const [startFrom, setStartFrom] = React.useState(1);
    const startN = Math.ceil((startFrom) * (n / duration));

    const [endAt, setEndAt] = React.useState(duration);
    const endN = Math.floor((endAt) * (n / duration));

    // Show and hide settings
    const [show, setShow] = React.useState(true);
    const toggleShow = () => setShow(!show);
    const c1 = show ? colors1 : ['transparent', 'transparent', 'transparent'];
    const c2 = show ? colors2 : ['transparent', 'transparent', 'transparent'];
    const c3 = show ? colors3 : ['transparent', 'transparent', 'transparent'];

    // Generate sim points
    const df = ACPC(
        duration,
        n,
        pi,
        ti,
        rr,
        peep,
        resistance,
        compliance,
        v0,
        spontaneous ? pmus : 0,
        nti,
        nShift
    );

    const dataframe = df.slice(startN, endN);

    return (
        <div className="flex flex-col text-xs">
            <div className={styles.title}>
                <p className="m-1">Loops with PC</p>
                <HCheckbox isOn={show} toggle={toggleShow} />
            </div>
            <div className="flex flex-row place-self-center">
                <Vent {...{ mode: 'pc', rr, setRr, pi, setPi, ti, setTi, peep, setPeep }} />
                <Lung {...{ v0, setV0, compliance, setCompliance, resistance, setResistance, spontaneous, setSpontaneous, pmus, setPmus, nti, setNti, nShift, setnShift }} />
                <div className={styles.graycard}>
                    <Input label="Loop Start (sec)" placeholder={startFrom} onChange={setStartFrom} />
                    <Input label="Loop End (sec)" placeholder={endAt} onChange={setEndAt} />
                </div>
            </div>

            <div className={styles.whitecard + " ventsim-chart"}>
                <div className="flex flex-row">
                    <div>
                        <div className="flex flex-col">
                            <Waveform df={df} x={"Time"} ys={pressures} ncol={2} strokeWidth={strokeWidth} colors={c1} autoScale={autoScale} yMin={-pMax / 5} yMax={pMax} />
                            <Waveform df={df} x={"Time"} ys={["Flow"]} ncol={2} strokeWidth={strokeWidth} colors={c2} autoScale={autoScale} yMin={-fMax} yMax={fMax} />
                            <Waveform df={df} x={"Time"} ys={["Volume"]} ncol={2} strokeWidth={strokeWidth} colors={c3} autoScale={autoScale} yMin={0} yMax={vMax} />
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-col">
                            <Loop df={dataframe} x={"Volume"} ys={pressures} strokeWidth={strokeWidth} colors={c1}
                                autoScale={autoScale} xMin={0} xMax={vMax} yMin={-pMax / 5} yMax={pMax} />
                            <Loop df={dataframe} x={"Flow"} ys={["Volume"]} strokeWidth={strokeWidth} colors={c3}
                                autoScale={autoScale} xMin={-fMax} xMax={fMax} yMin={0} yMax={vMax} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoopPC;