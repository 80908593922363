import React from "react";

type TextInputProps = {
  label: string;
  value: string;
  placeholder?: string;
  onChange: Function;
  disabled?: boolean
};

export default function TextInput({ label, value, placeholder, onChange, disabled }: TextInputProps) {
  const row = "flex m-1";
  const colLabel = "w-1/3";
  const colInput = "w-2/3 text-center rounded";

  return (
    <div className={row}>
      <label className={colLabel}>{label}</label>
      <input
        value={value}
        disabled={disabled}
        className={colInput}
        placeholder={placeholder}
        type="text"
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
}

