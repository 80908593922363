import React from 'react';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import { DEBUG } from '../App';

// Set to false to enforce checking invitation expiration date
const IgnoreInvitationExpirationDate = true;
// TODO - add type declaration to UserProvider and UserContext
const UserContext = React.createContext<Partial<any>>({});

function UserProvider(props: any) {
    // User information
    const [user, setUser] = React.useState({} as firebase.User);
    // Time tracking and time keeping variables
    const [aggregateTime, setAggregateTime] = React.useState(-1);
    const [sessionTime, setSessionTime] = React.useState(-1);
    const [time1, setTime1] = React.useState(-1);
    const [isInvited, setIsInvited] = React.useState(false);

    // Helper variables and functions
    const provider = new firebase.auth.GoogleAuthProvider();
    const getServerTime = () => firebase.firestore.Timestamp.now().seconds;

    // The following logic runs only once when the component is loaded. 
    // It pulls user information & usage data from firebase backend
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            // DEBUG LOGIC
            if (DEBUG) console.log('VS - Debug UE-1');
            // RESET SESSION TIME
            console.log('VS - Session change detected - Resetting Time');
            setTime1(getServerTime());
            setSessionTime(0);
            if (user && user.uid) {
                // UPDATE LOCAL STORAGE WHEN USER SIGNS IN
                console.log("VS - Signed in user# " + user.uid);
                localStorage.setItem('user', JSON.stringify(user));
                setUser(user);
                // RETRIEVE AGGREGATE TIME
                resetAggTime(user);
                // CHECK IF USER IS INVITED
                const email = user.email?.toLowerCase();
                firebase.firestore().collection("reactinvitedusers").doc(email).get().then(
                    doc => {
                        const data = doc.data();
                        if (data && 'expirationdate' in data) {
                            const expdate = new Date(data.expirationdate);
                            const expdateinsec = expdate.getTime() / 1000;
                            const todayinsec = firebase.firestore.Timestamp.now().seconds;
                            console.log(`VS - Invitation Expiration Date: ${expdate}`);
                            if (IgnoreInvitationExpirationDate || expdateinsec > todayinsec) {
                                setIsInvited(true);
                                localStorage.setItem('invited', 'true');
                            } else {
                                setIsInvited(false);
                                localStorage.removeItem('invited');
                            }
                        }
                        else {
                            setIsInvited(false);
                            localStorage.removeItem('invited');
                        }
                    }
                ).catch(error => console.log(error));
            } else {
                // REST VARIABLES WHEN USER SIGNS OUT
                setUser({} as firebase.User);
                setAggregateTime(-1);
                setSessionTime(-1);
                setTime1(-1);
                setIsInvited(false);
                localStorage.removeItem('user');
                localStorage.removeItem('invited');
            }
        })
    }, []);

    // 
    React.useEffect(() => {

    }, [user]);

    // The following logic runs when the aggregate time is changed by the user interacting with the app.
    // It writes the aggregate time to firebase database
    React.useEffect(() => {
        if (DEBUG) console.log('VS - Debug UE-2');
        if (user && user.uid && aggregateTime > 0) {
            const timestamp = firebase.firestore.Timestamp.now();
            const db = firebase.firestore().collection("reactaggregatetime");
            db.doc(user.uid).set({
                uid: user.uid, email: user.email, displayName: user.displayName,
                aggregateTimeInSec: aggregateTime, timestamp: timestamp
            });
        }
    }, [aggregateTime, user]);

    const trackTime = () => {
        const timeoutsec = 5 * 60; // five minutes
        if (user && user.uid) {
            const time2 = getServerTime();
            const dt = time2 - time1;
            if (dt < timeoutsec) {
                if (dt > 0) console.log(`VS - Adding to session time ${dt} secs.`);
                setSessionTime(sessionTime + dt);
                if (aggregateTime >= 0) setAggregateTime(aggregateTime + dt);
            }
            else {
                console.log("VS - Timeout due to inactivity.");
                setSessionTime(0);
                resetAggTime(user);
            }
        }
        setTime1(getServerTime());
    }

    /**
     * Writes the routes the user takes to firestore backends for analytics purposes
     */
    const trackRoute = (route: string) => {
        if (user && user.uid) {
            console.log(`VS - Tracking path ${route}`);
            const timestamp = firebase.firestore.Timestamp.now();
            const db = firebase.firestore().collection('reactusage');
            const tagname = 'reactapp';
            db.add({
                scenario: route, uid: user.uid, displayName: user.displayName,
                email: user.email, timestamp: timestamp, tag: tagname, anonymous: user.isAnonymous,
                phone: user.phoneNumber, aggregateTimeInSec: aggregateTime, browser: navigator.userAgent
            });
        }
    }

    /**
     * Resets the registered aggregate time by pulling the stored data from the firestore backend
     */
    const resetAggTime = (user: firebase.User) => {
        firebase.firestore().collection("reactaggregatetime").doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    if (data) {
                        const aggTimeSec = data.aggregateTimeInSec;
                        if (aggTimeSec >= 0) setAggregateTime(aggTimeSec);
                    }
                }
                else {
                    setAggregateTime(0);
                }
            });
    }

    const signIn = (callback: Function) => {
        firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                // callback to switch route
                setTimeout(callback, 500);
            }).catch((error) => {
                console.log(error.message);
            });
    }

    const signOut = (callback: Function) => {
        firebase.auth().signOut().then((response) => {
            if (DEBUG) console.log(response)
            console.log("VS - Bye Bye!");
            setUser({} as firebase.User);
            setAggregateTime(-1);
            setSessionTime(-1);
            setTime1(-1);
            setIsInvited(false);
            localStorage.removeItem('user');
            localStorage.removeItem('invited');
            // callback to switch route
            setTimeout(callback, 500);
        }
        );
    };

    return (
        <UserContext.Provider value={{ signIn, signOut, user, isInvited, sessionTime, aggregateTime, trackRoute, trackTime}}>
            {props.children}
        </UserContext.Provider>
    );
}

export { UserProvider, UserContext };