import React from 'react';
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import Button from '../components/ui/Button';
import Table from '../components/ui/Table';

/**
 * This component connects to firebase backend, and fetches analytics data. 
 * It displays them in a table format.
 * @returns HTML Table based on data from reactaggregatetime, reactusage, or
 * reactinvitedusers firestore databases
 */
export default function Analytics() {
    const [state, setState] = React.useState('');
    const [data, setData] = React.useState([] as Array<any>);
    const [message, setMessage] = React.useState('');
    const fetchCollection = (collectionName: string) => firebase.firestore().collection(collectionName).orderBy("timestamp", "desc").get();

    const headers = ["uid", "displayName", "email", "aggregateTimeInSec", "scenario", "timestamp", "expirationdate"];

    const fetchDocs = (collectionName: string) => {
        setState('Fetching');
        let result : Array<any> = [];
        fetchCollection(collectionName)
            .then(querySnapShot => {
                querySnapShot.forEach(doc => result.push(doc.data()));
                setMessage(`${result.length} docs retrieved`);
                result.length > 0 ? setState('Success') : setState('No Data');
            })
            .catch(err => {
                setState('Error');
                setMessage(err.message);
            });
        setData(result)
    };

    return (
        <div className="flex flex-col justify-center text-sm">
            <div className="flex justify-center items-center">
                <Button color="blue" onClick={() => fetchDocs("reactaggregatetime")}>React Agg. Data</Button>
                <Button color="blue" onClick={() => fetchDocs("reactusage")}>React Detailed Data</Button>
                <Button color="blue" onClick={() => fetchDocs("reactinvitedusers")}>React Invited Users</Button>
                <Button disabled color={state === "Success" ? "green" : "red"} >{state}</Button>
                <div className="flex justify-center">{message}</div>
            </div>

            <div className="flex justify-center">
                {state !== 'Error' && data.length > 0 ? <Table headers={headers} data={data} /> : <></>}
            </div>
        </div >);
}