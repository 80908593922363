import React, { ReactNode } from "react";
// @ts-ignore
import * as QueryString from "query-string";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [mainurl, querystring] = window.location.href.split('?');
const params = QueryString.parse(querystring);

console.log(`Add a query string to the url to change default settings.\n 
Simulation Settings: n, duration, showPalv, showPmus, thickLines, autoScale, pMax, fMax, vMax\n
Lung Settings: v0, resistance, compliance, pmus, nti, spontaneous, nShift\n
Vent Settings: rr, tv, flow, peep, pi, ti, sqWave`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props ={
    children: ReactNode | ReactNode[];
    n: number;
    setN: Function;
    setDuration: Function;
    duration: number;
    showPalv: boolean;
    showPmus: boolean;
    thickLines: boolean;
    strokeWidth: number;
    pressures: Array<any>;
    autoScale: boolean;
    pMax: number;
    fMax: number;
    vMax: number;
    togglePalv: Function;
    togglePmus: Function;
    toggleThickLines: Function;
    toggleAutoScale: Function;
    resetSettings: Function;
    setpMax: Function;
    setfMax: Function;
    setvMax: Function
};

const SettingsContext = React.createContext<Partial<any>>({});

const defaultSimSettings = {
    n: params.n ? parseInt(params.n) : 1000,
    duration: params.duration ? parseInt(params.duration) : 10,
    showPalv: params.showPalv ? parseInt(params.showPalv) !== 0 : true,
    showPmus: params.showPmus ? parseInt(params.showPmus) !== 0 : true,
    thickLines: params.thickLines ? parseInt(params.thickLines) !== 0 : false,
    autoScale: params.autoScale ? parseInt(params.autoScale) !== 0 : true,
    pMax: params.pMax ? parseInt(params.pMax) : 25,
    fMax: params.fMax ? parseInt(params.fMax) : 120,
    vMax: params.vMax ? parseInt(params.vMax) : 1000
}

const defaultLungSettings = {
    v0: params.v0 ? parseFloat(params.v0) : 0,
    resistance: params.resistance ? parseFloat(params.resistance) : 5,
    compliance: params.compliance ? parseFloat(params.compliance) : 0.1,
    pmus: params.pmus ? parseFloat(params.pmus) : 3,
    nti: params.nti ? parseFloat(params.nti) : 1.0,
    spontaneous: params.spontaneous ? parseInt(params.spontaneous) !== 0 : false,
    nShift: params.nShift ? parseFloat(params.nShift) : 0.0
}

const defaultVentSettings = {
    rr: params.rr ? parseInt(params.rr) : 15,
    tv: params.tv ? parseFloat(params.tv) : 0.5,
    flow: params.flow ? parseFloat(params.flow) : 60,
    peep: params.peep ? parseFloat(params.peep) : 5,
    pi: params.pi ? parseFloat(params.pi) : 5.5,
    ti: params.ti ? parseFloat(params.ti) : 1,
    sqWave: params.sqWave ? parseInt(params.sqWave) !== 0 : false,
}

function SettingsProvider(props: any) {
    const [duration, setDuration] = React.useState(defaultSimSettings.duration);
    const [n, setN] = React.useState(defaultSimSettings.n);
    const [showPalv, setShowPalv] = React.useState(defaultSimSettings.showPalv);
    const togglePalv = () => setShowPalv(!showPalv);
    const [showPmus, setShowPmus] = React.useState(defaultSimSettings.showPmus);
    const togglePmus = () => setShowPmus(!showPmus);
    // pressure list to display as waveforms
    let pressures = ["Paw"];
    if (showPalv) pressures.push("Palv");
    if (showPmus) pressures.push("Pmus");
    // linethickness
    const [thickLines, setThickLines] = React.useState(defaultSimSettings.thickLines);
    const toggleThickLines = () => setThickLines(!thickLines);
    const strokeWidth = thickLines ? 3 : 2;
    // custom scales
    const [autoScale, setAutoScale] = React.useState(defaultSimSettings.autoScale);
    const toggleAutoScale = () => setAutoScale(!autoScale);
    const [pMax, setpMax] = React.useState(defaultSimSettings.pMax);
    const [fMax, setfMax] = React.useState(defaultSimSettings.fMax);
    const [vMax, setvMax] = React.useState(defaultSimSettings.vMax);

    const resetSettings = () => {
        setDuration(defaultSimSettings.duration);
        setN(defaultSimSettings.n);
        setShowPalv(defaultSimSettings.showPalv);
        setShowPmus(defaultSimSettings.showPmus);
        setThickLines(defaultSimSettings.thickLines);
        setAutoScale(defaultSimSettings.autoScale);
        setpMax(defaultSimSettings.pMax);
        setfMax(defaultSimSettings.fMax);
        setvMax(defaultSimSettings.vMax);
    };

    return (<SettingsContext.Provider
        value={{
            n, setN, setDuration, duration, showPalv, showPmus, thickLines, strokeWidth, pressures,
            autoScale, pMax, fMax, vMax, togglePalv, togglePmus, toggleThickLines, toggleAutoScale, 
            resetSettings, setpMax, setfMax, setvMax
        }}>
        {props.children}
    </SettingsContext.Provider>
    );

}

export {defaultLungSettings, defaultVentSettings, defaultSimSettings, SettingsProvider, SettingsContext}