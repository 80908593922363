import React from 'react';
import { VERSION } from '../App';
import { UserContext } from '../contexts/User';

/**
 * The UsageInfo is displayed in the top right corner of the app
 * It displays app version, session time, and aggregate time respectively
 */
export default function UsageInfo() {
    const { sessionTime, aggregateTime } = React.useContext(UserContext);

    return (
            <div className="ventsim-debug-box text-xs text-gray-500 m-1">
                <div>V{VERSION}</div>
                <div>{sessionTime}</div>
                <div>{aggregateTime}</div>
            </div>
    );
}