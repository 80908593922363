import React from 'react';
import { v4 as uuidv4 } from 'uuid';

// THIS MODULE PROVIDES TABLE CREATIONS FUNCTIONALITY

/**
 * Takes an object retreived from backend database with the structure: 
 * {uid: 123456, displayName: "Super Mario", ...}
 * @returns Returns an HTML table row representing the information.
 */
function tableRow(items: any, headers: string[]) {
    const twstyle = "border px-4 py-1"
    return (
        <tr key={uuidv4()}>
            {headers.map((key) => {
                // convert timestamp objects to date strings that can be shown in the table
                const item = items[key] && key === 'timestamp' ? items[key].toDate().toString() : items[key];
                // if (items[key] && key === 'timestamp' ) console.log(items[key])
                return <td key={uuidv4()} className={twstyle}>{item}</td>
            })}
        </tr>
    );
}

/**
 * Converts an array of strings ['uid', 'email', ...] into an HTML table header
 */
function tableHeader(headers: Array<string>) {
    const twstyle = "bg-gray-100 border text-left px-4 py-1"
    return (
        <tr key={uuidv4()}>
            {headers.map(header => <th key={uuidv4()} className={twstyle}>{header}</th>)}
        </tr>
    );
}

type TableParams = {data: Object[], headers: string[]};
/**
 * Converts data retreived from firestore backend into HTML table
 * @returns HTML Table
 */
export default function Table({ data, headers }: TableParams) {
    return (
        <table className="table-auto row m-2 bg-white">
            <thead>
                {tableHeader(headers)}
            </thead>
            <tbody>
                {data.map(data => tableRow(data, headers))}
            </tbody>
        </table>);
}