import React from 'react';

/**
 * This page pulls the old simulator as an iframe
 */
export default function OpenSim() {
  return (
    <iframe className="ventsim-iframe w-screen h-screen" style={{ overflow: 'hidden' }}
      src="https://ccsimmods.firebaseapp.com/mechanicalventilation/interactive/"
      title="Mechanical Ventilator Simulator"></iframe>
  );
}