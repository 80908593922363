import React from 'react';
import { UserContext } from '../contexts/User';

/**
 * This component represents the app area. Clicking on any component 
 * in this area will trigger a tracking request to the backend.
 */
const Tracker: React.FC = ({ children }) => {
    const { trackRoute, trackTime } = React.useContext(UserContext);

    return (
        <div className="ventsim-tracking-area" onClick={(e) => {
            // update session and aggregate time
            trackTime();
            // write updated time information, and route taken to firestore backend
            const target = e.target as HTMLElement
            const pathlocation = target.getAttribute('href');
            if (pathlocation)
                trackRoute(pathlocation);
        }}>
            {children}
        </div>
    );
}

export default Tracker;