export const graycard =
    "flex flex-col rounded shadow-sm bg-gray-300 p-1 m-1";

export const graybar =
    "flex justify-center rounded shadow-sm bg-gray-300 px-1 m-1";

export const whitecard =
    "my-3 flex flex-col";

export const title =
    "flex rounded shadow-sm bg-gray-300 m-1 p-1 font-bold place-self-center";

