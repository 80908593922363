export default function ACVC(
  duration = 10,
  n = 500,
  rr = 10,
  tv = 0.5,
  flow = 30,
  wave = "SQ",
  peep = 5,
  r = 10,
  c = 0.05,
  v0 = 0,
  mPmus = 2,
  nTi = 0.8,
  nShift = -0.05
) {
  const given = { duration: duration, n: n };
  const vent = { rr: rr, tv: tv, flow: flow, wave: wave, peep: peep };
  const lung = { resistance: r, compliance: c, v0: v0, mPmus: mPmus, nTi: nTi, nShift: nShift };
  // computed variables
  const breathTime = 60.0 / vent.rr;
  const dt = given.duration / given.n;
  const ti = vent.wave === "SQ" ? vent.tv / (vent.flow / 60) : (2 * vent.tv) / (vent.flow / 60);
  const ticks = Array.from(Array(given.n).keys());
  const Time = ticks.map(t => (t * given.duration) / given.n);
  const Volume = Array(given.n);
  const Flow = Array(given.n);
  const Paw = Array(given.n);
  const Palv = Array(given.n);
  const timeInCycle = Time.map(t => t % breathTime);
  const phaseInCycle = timeInCycle.map(t => (t < ti ? "INSP" : "EXP"));

  // calculate pmus
  const lungTimeInCycle = Time.map(t => (t - lung.nShift) % breathTime);
  const lungPhaseInCycle = lungTimeInCycle.map(t => (t < lung.nTi ? "INSP" : "EXP"));
  const lungInsp = lungPhaseInCycle.map(p => (p === "INSP" ? 1 : 0));
  const PmusTemp = ticks.map(i => 
    (-4 * lungInsp[i] * lung.mPmus * lungTimeInCycle[i] * (lung.nTi - lungTimeInCycle[i])) / lung.nTi ** 2);
  const Pmus = PmusTemp.map(p => (p > 0 ? 0 : p));
  const PmusPlusPEEP = Pmus.map(p => p + vent.peep);

  Volume[0] = lung.v0;
  for (let t = 1; t < given.n; t++) {
    // Calculate Flow
    // *Inspiration
    if (phaseInCycle[t] === "INSP")
      if (vent.wave === "SQ") Flow[t] = vent.flow / 60;
      else Flow[t] = (vent.flow / 60) * (1 - timeInCycle[t] / ti);
    // *Expiration
    else
      Flow[t] = (-Pmus[t - 1] - (Volume[t - 1] / lung.compliance)) / lung.resistance;
    // Calculate volume
    Volume[t] = Volume[t - 1] + Flow[t] * dt;
    // Calculate airway pressures
    Palv[t] = Volume[t] / lung.compliance + vent.peep;
    Paw[t] = Flow[t] * lung.resistance + Palv[t] + Pmus[t];
  }
  return ticks.map(t =>
    Object({
      Time: Time[t] + dt,
      Paw: Paw[t],
      Palv: Palv[t],
      Pmus: Pmus[t],
      Flow: Flow[t] * 60,
      Volume: Volume[t] * 1000,
      PmusPlusPEEP: PmusPlusPEEP[t]
    })
  );
}
