import React from 'react';
import PropTypes from 'prop-types';
import HCheckbox from "../components/ui/HCheckbox";
import Waveform from "../components/chart/Waveform";
import ACPC from "../logic/ACPC";
import { SettingsContext, defaultLungSettings, defaultVentSettings } from '../contexts/Settings';
import * as styles from "../misc/styles";
import { colors1, colors2, colors3 } from "../misc/colors";
import Vent from '../components/sim/Vent';
import Lung from '../components/sim/Lung';

function SimPC({ widthFactor = 1 }) {
    //Vent
    const [rr, setRr] = React.useState(defaultVentSettings.rr);
    const [pi, setPi] = React.useState(defaultVentSettings.pi);
    const [ti, setTi] = React.useState(defaultVentSettings.ti);
    const [peep, setPeep] = React.useState(defaultVentSettings.peep);

    // Lung
    const [resistance, setResistance] = React.useState(defaultLungSettings.resistance);
    const [compliance, setCompliance] = React.useState(defaultLungSettings.compliance);
    const [v0, setV0] = React.useState(defaultLungSettings.v0);
    const [pmus, setPmus] = React.useState(defaultLungSettings.pmus);
    const [nti, setNti] = React.useState(defaultLungSettings.nti);
    const [spontaneous, setSpontaneous] = React.useState(defaultLungSettings.spontaneous);
    const [nShift, setnShift] = React.useState(defaultLungSettings.nShift);

    // Simulation Settings
    const { duration, n, pressures, strokeWidth, autoScale, pMax, fMax, vMax } = React.useContext(SettingsContext);

    // Show and hide settings
    const [show, setShow] = React.useState(true);
    const toggleShow = () => setShow(!show);
    const c1 = show ? colors1 : ['transparent', 'transparent', 'transparent'];
    const c2 = show ? colors2 : ['transparent', 'transparent', 'transparent'];
    const c3 = show ? colors3 : ['transparent', 'transparent', 'transparent'];

    // Generate sim points
    const df = ACPC(
        duration,
        n,
        pi,
        ti,
        rr,
        peep,
        resistance,
        compliance,
        v0,
        spontaneous ? pmus : 0,
        nti,
        nShift
    );

    return (
        <div className="flex flex-col text-xs">
            <div className={styles.title}>
                <p className="m-1">Pressure-Control</p>
                <HCheckbox isOn={show} toggle={toggleShow} />
            </div>
            <div className="flex flex-row place-self-center">
                <Vent {...{ mode: 'pc', rr, setRr, pi, setPi, ti, setTi, peep, setPeep }} />
                <Lung {...{ v0, setV0, compliance, setCompliance, resistance, setResistance, spontaneous, setSpontaneous, pmus, setPmus, nti, setNti, nShift, setnShift }} />
            </div>

            <div className={styles.whitecard + " ventsim-chart justify-center place-self-center"}>
                <Waveform df={df} x={"Time"} ys={pressures} strokeWidth={strokeWidth} colors={c1} ncol={widthFactor} autoScale={autoScale} yMin={-pMax / 5} yMax={pMax} />
                <Waveform df={df} x={"Time"} ys={["Flow"]} strokeWidth={strokeWidth} colors={c2} ncol={widthFactor} autoScale={autoScale} yMin={-fMax} yMax={fMax} />
                <Waveform df={df} x={"Time"} ys={["Volume"]} strokeWidth={strokeWidth} colors={c3} ncol={widthFactor} autoScale={autoScale} yMin={0} yMax={vMax} />
            </div>
        </div>
    );
}

SimPC.propTypes = {
    twoColumns: PropTypes.bool
};
export default SimPC;