// Firebase config settings and initialize function

import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCRJx9w3YnpQTAAc2xAGWt42GRDe0aK6cw",
    authDomain: "ccsimmods.firebaseapp.com",
    databaseURL: "https://ccsimmods.firebaseio.com",
    projectId: "ccsimmods",
    storageBucket: "ccsimmods.appspot.com",
    messagingSenderId: "152024744079",
    appId: "1:152024744079:web:2423a9002b6c2ccb",
    measurementId: "G-V3VDCHFX7E"
};

export default function connect2firebase() {
    firebase.initializeApp(firebaseConfig);
}