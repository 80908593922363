import React from 'react';
import { UserContext } from '../../contexts/User';

interface LoginButtonTypes { onClick: React.MouseEventHandler, children: Object };

export default function LoginButton({ onClick, children }: LoginButtonTypes) {
    const { user } = React.useContext(UserContext);

    const color = user && user.uid ? "border-yellow-500 text-yellow-500 hover:bg-yellow-600" : "border-green-500 text-green-500 hover:bg-green-600";
    const buttonStyle = "rounded-sm px-2  transition duration-500 ease select-none hover:text-white focus:outline-none focus:shadow-outline";
    return (
        <button
            type="button"
            className={color + " " + buttonStyle}
            onClick={onClick}
        >
            {children}
        </button>);
}