import React from 'react';
import Card from '../components/ui/Card'

const title = <h1 className="font-bold text-lg">Mechanical Ventilation Simulator (VentSim)</h1>
const content = (
    <div className="content">
        <p className="mb-5">
            Discover the life-saving world of mechanical ventilation in the comfort of your own space!
            Our app offers a professional and immersive simulation of patient-ventilator interactions, complete with interactive waveforms.
            Explore various modes of mechanical ventilation and gain valuable experience by exploring different settings in realistic clinical scenarios.</p>
        <p className="mb-5">
            To take full advantage of our app's features, simply create an account with us. Don't worry, the process is quick and easy.
            Just provide your name and email address, and you'll be on your way to mastering this essential skill in modern intensive care practice.</p>
        <p className="mb-5">
            Please note that this website/app serves purely as an illustrative tool, complementing your learning experience.
            While it does not substitute expert medication judgment or medical care, we encourage you to explore this valuable resource and enhance your knowledge in the field.</p>
        <p className="mb-5">
            To access to our website/app, we kindly request you to sign up. As part of ensuring a secure authentication process, our app utilizes Google's authentication services to confirm your identity.
            We never ask for nor do we have access to your password.
            Upon successful authentication via Google, we would appreciate it if you could provide us with your name, institution, and title to complete the sign-up procedure. </p>
        <p className="mb-5">
            Finally, by signing up for this website or app, you gain complimentary access to our ventilator simulation modules, and you agree to anonymously share your usage data. The data will be used in research focused on medication education. We also may ask you to take periodic surveys, and we sincerely value your participation in them.
            Be sure to review the comprehensive details outlined in our <a className="text-blue-600" target="_blank" rel="noopener noreferrer" href="https://ccsimmods.firebaseapp.com/terms-conditions.html"> Terms and Conditions</a> and
            <a className="text-blue-600" target="_blank" rel="noopener noreferrer" href="https://ccsimmods.firebaseapp.com/privacy-policy.html"> Privacy Policy</a> for further information.</p>
        <p className="mb-5">
            We highly encourage you to get in touch with us for feedback, as it would mean a lot to us. Please feel free to email us at:
            <a className="text-blue-600 mx-1" href="mailto:VentSimCC@gmail.com?subject=VentSim Access Request">VentSimCC@gmail.com</a> and for additional learning content, please check our companion site
            <a className="text-blue-600" target="_blank" rel="noopener noreferrer" href="https://iculearning.com/"> ICULearning.com</a>
        </p>
    </div>
);

type HomeProps = { callback?: Function }

/**
 * Home page for the App. Takes a callback function to call when component
 * is intiated. In this app, this is used to reset general settings when user
 * goes to home page.
 * @param {function} callback a function that gets called once component is loaded. 
 */
function Home({ callback }: HomeProps) {
    if (callback) callback();
    return (
        <div>
            <Card imgSrc={'./icons/logo512.png'} title={title}>
                {content}
            </Card>
        </div>
    );
}

export default Home;