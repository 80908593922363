import React from "react";

type ButtonProps = { 
    color?: string; 
    disabled?: boolean; 
    onClick?: React.MouseEventHandler; 
    children: Object }

export default function Button(props: ButtonProps) {
    const color = props.color ? props.color : "blue";

    const hover = props.disabled ?
        '' :
        `hover:bg-${color}-600 hover:text-white`;

    const style =
        `border-${color}-500 text-${color}-500 p-1 m-1 border rounded-md transition duration-500 ease select-none focus:outline-none`;
    return (
        <button
            disabled={props.disabled}
            type="button"
            className={`${hover} ${style}`}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
}