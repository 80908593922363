import React from 'react';

/**
 * This page pulls the old simulator as an iframe
 */
export default function OtherInflection() {
    return (
        <iframe id="embeddedsims" className="ventsim-iframe w-screen h-screen" style={{ overflow: 'hidden' }}
            src="https://ccsimmods.firebaseapp.com/mechanicalventilation/?mode=VCDC"
            title="Mechanical Ventilator Simulator - VCDC"></iframe>
    );
}