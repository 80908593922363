export default function VT(
  duration = 10,
  n = 500,
  tv = 0.5,
  ti = 1,
  rr = 10,
  peep = 5,
  r = 10,
  c = 0.05,
  v0 = 0,
  mPmus = 2,
  nTi = 0.8,
  nShift = -0.1
) {

  // Set initial inspiratory pressure
  let pi = tv / c;
  if (pi < 0) pi = 0;
  // default parameters
  const given = { duration: duration, n: n };
  const vent = { pi: pi, ti: ti, rr: rr, peep: peep, tv: tv };
  const lung = { resistance: r, compliance: c, v0: v0, mPmus: mPmus, nTi: nTi, nShift: nShift };

  // computed variables
  const breathTime = 60.0 / vent.rr;
  const dt = given.duration / given.n;

  const ticks = Array.from(Array(given.n).keys());
  const Time = ticks.map(t => (t * given.duration) / given.n);
  const Volume = Array(given.n);
  const Flow = Array(given.n);
  const Paw = Array(given.n);
  const Palv = Array(given.n);
  const timeInCycle = Time.map(t => t % breathTime);
  const phaseInCycle = timeInCycle.map(t => (t < vent.ti ? "INSP" : "EXP"));
  // Array that holds the number of the cycle
  // [0, 0, 0, 0, 1, 1, 1, 2, 2, 2, 2, ...]
  const cycleNumber = Time.map(t => Math.floor(t / breathTime));
  // Array that holds Arrays, the inner arrays holds the volumes for each cycle
  // [[volumes for cycle 1], [volumes for cycle 2], ...]
  const Cycles = Array(Math.max(...cycleNumber) + 1);
  for (let i = 0; i < Cycles.length; i++) Cycles[i] = Array(0);

  // calculate pmus
  const lungTimeInCycle = Time.map(t => (t - lung.nShift) % breathTime);
  const lungPhaseInCycle = lungTimeInCycle.map(t => (t < lung.nTi ? "INSP" : "EXP"));
  const lungInsp = lungPhaseInCycle.map(p => (p === "INSP" ? 1 : 0));
  const PmusTemp = ticks.map(
    i => (-4 * lungInsp[i] * lung.mPmus * lungTimeInCycle[i] * (lung.nTi - lungTimeInCycle[i])) / lung.nTi ** 2);
  const Pmus = PmusTemp.map(p => (p > 0 ? 0 : p));
  const PmusPlusPEEP = Pmus.map(p => p + vent.peep);

  Volume[0] = lung.v0;
  Cycles[0].push(Volume[0]);
  for (const t of ticks) {
    let currentCycle = cycleNumber[t];
    // If we are at a new cycle, then we need to recalculate pi
    if (Cycles[currentCycle].length === 0) {
      let previousCycle = cycleNumber[t] - 1;
      let deliveredVolume = Math.max(...Cycles[previousCycle]) - Math.min(...Cycles[previousCycle]);
      // console.log(deliveredVolume)
      let volumeFraction = deliveredVolume / vent.tv;
      vent.pi = vent.pi / volumeFraction;
    }
    Cycles[currentCycle].push(Volume[t])
    Paw[t] = phaseInCycle[t] === "INSP" ? vent.pi + vent.peep : vent.peep;
    let dv = ((Paw[t] - Pmus[t] - Volume[t] / lung.compliance - vent.peep) / lung.resistance) * dt;
    Volume[t + 1] = Volume[t] + dv;
    Flow[t] = dv / dt;
    Palv[t] = Volume[t] / lung.compliance + vent.peep;
  }

  return ticks.map(t =>
    Object({
      Time: Time[t] + dt,
      Paw: Paw[t],
      Palv: Palv[t],
      Pmus: Pmus[t],
      Flow: Flow[t] * 60,
      Volume: Volume[t] * 1000,
      PmusPlusPEEP: PmusPlusPEEP[t]
    })
  );
}
