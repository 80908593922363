import React from 'react';
import Button from '../components/ui/Button';
import Card from '../components/ui/Card'
import TextInput from '../components/ui/TextInput';
import firebase from "firebase/app";
import { DEBUG } from '../App';

const title = <h1 className="font-bold text-lg">Invite User to the Ventilator Simulator App</h1>

const svg = <svg width="54px" height="54px" aria-hidden="true" focusable="false" data-prefix="fas"
    data-icon="user-plus" className="svg-inline--fa fa-user-plus fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"><path fill="#644C63" d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>

function add2today(years = 0, months = 0, days = 0) {
    const today = new Date();
    const yyyy = today.getFullYear() + years;
    // const mm = today.getMonth() + months;
    // const dd = today.getDay() + days;
    return `${yyyy}-01-01`;
}

function validateEmail(email: string) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return (true)
    }
    return (false)
}

/**
 * Invite users page for the App. 
 */
function InviteUser() {
    const [displayName, setDisplayName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [institution, setInstitution] = React.useState('');
    const [role, setRole] = React.useState('');
    const [expirationdate, setExpirationdate] = React.useState(add2today(1));
    const [message, setMessage] = React.useState('');
    const db = firebase.firestore().collection("reactinvitedusers")

    const submit = () => {
        if (validateEmail(email)) {
            setMessage('Submitting...');
            const timestamp = firebase.firestore.Timestamp.now();
            const email2lowercase = email.toLowerCase();
            db.doc(email2lowercase).set({
                displayName, email: email2lowercase, institution, role, expirationdate, timestamp: timestamp
            }).then(response => {
                if (DEBUG) console.log(response);
                setMessage('Success');
                // Clear Privous Messages
                setTimeout(() => { 
                    setDisplayName('');
                    setEmail('');
                    setInstitution('');
                    setRole('');
                    setMessage('') }, 1000)
            }).catch(error => {
                console.log(error);
                setMessage(error.message);
            });
        } else {
            setMessage('Invalid email address format')
        }
    }
    return (
        <div>
            <Card title={title} svg={svg}>
                <div className={''}>
                    <TextInput label="Name" placeholder='Novice Learner' value={displayName} onChange={setDisplayName} />
                    <TextInput label="Email Address" placeholder='user@ventsim.cc' value={email} onChange={setEmail} />
                    <TextInput label="Institution" placeholder='Institution or Hospital' value={institution} onChange={setInstitution} />
                    <TextInput label="Title" placeholder='Title or Role' value={role} onChange={setRole} />
                    <TextInput label="Expiration" value={expirationdate} onChange={setExpirationdate} />
                </div>
                <div className="flex mt-10 justify-center">
                    <Button disabled color={'gray'}>{new Date(expirationdate).toDateString()}</Button>
                    <Button onClick={submit}>Submit</Button>
                    <Button disabled color={'yellow'}>{message}</Button>

                </div>
            </Card>
        </div>
    );
}

export default InviteUser;