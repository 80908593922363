import React from 'react';
import SimVC from './SimVC'
import SimPC from './SimPC';
import SimVT from './SimVT';

export default function CompareVCPCVT() {
    return (
        <div className={"grid grid-cols-3"}>
            <SimVC widthFactor={3} />
            <SimPC widthFactor={3} />
            <SimVT widthFactor={3} />
        </div>
    );
}