import React from "react";

type HInputProps = {
  label: string;
  value: string;
  unit?: string;
  disabled?: boolean;
  placeholder?: string | number;
  onChange: Function;
};

export default function HInput({ label, unit, placeholder, onChange, value, disabled }: HInputProps) {
  const row = "flex";
  const colLabel = "flex m-1";
  const colUnit = "hidden lg:block";
  const colInput = "w-12 lg:w-16 m-1 text-center rounded";
  let step = 1.0;
  if (label.startsWith("C") || label.startsWith("TV") || label.startsWith("Neural") || label.startsWith("V0"))
    step = 0.05;

  return (
    <div className={row}>
      <label className={colLabel}><span>{label}</span>&nbsp;<span className={colUnit}>{unit}</span></label>
      <input
        value={value}
        disabled={disabled}
        className={colInput}
        placeholder={placeholder?.toString()}
        type="number"
        onChange={e => onChange(Number(e.target.value)) }
        step={step}
      />
    </div>
  );
}
