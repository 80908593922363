import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { Routes } from './Router';

interface PrivateRouteProps {
    path?: string
};

/**
 * Private route that redirects uninvited users away from sim scenarios.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
    const user = localStorage.getItem('user');
    const invited = localStorage.getItem('invited') === 'true';

    return (
        <Route
            {...rest}
            render={({ location }) =>
                user && invited ? children :
                    <Redirect to={{ pathname: Routes.login, state: { from: location } }} />
            }
        />
    );
}

export default PrivateRoute;