import React from "react";

type InputProps = {
  label: string;
  value?: string;
  unit?: string;
  disabled?: boolean;
  placeholder?: string | number;
  onChange: Function;
};

export default function Input({ label, placeholder, onChange, disabled }: InputProps) {
  const row = "flex m-1";
  const colLabel = "w-2/3";
  const colInput = "w-1/3 text-center rounded";
  let step = 1.0;
  if (label.startsWith("C") || label.startsWith("TV") || label.startsWith("Neural") || label.startsWith("V0"))
    step = 0.025;

  return (
    <div className={row}>
      <label className={colLabel}>{label}</label>
      <input
        disabled={disabled}
        className={colInput}
        placeholder={placeholder?.toString()}
        type="number"
        onChange={e => onChange(Number(e.target.value))}
        step={step}
      />
    </div>
  );
}