import React from 'react';
import HInput from "../ui/HInput";
import HCheckbox from "../ui/HCheckbox";
import { SettingsContext } from '../../contexts/Settings';
import { useLocation } from 'react-router';

const grayCapsuleStyle = "flex justify-center rounded shadow-sm bg-gray-300 p-1 m-1 ";

/**
 * Component that holds general settings for the simulation which are shared across all scenarios.
 */
export default function SimSettings() {
    const { duration, setDuration, n, setN, showPalv, togglePalv, showPmus, togglePmus,
        thickLines, toggleThickLines, autoScale, toggleAutoScale, pMax, setpMax, fMax,
        setfMax, vMax, setvMax } = React.useContext(SettingsContext);

    // hide this component for non-simulation routes where settings are irrelevant
    const location = useLocation();
    const hidingRoutes = ['/', '/login', '/opensim', '/workshop', '/analytics', '/inviteuser', '/aprv', '/ps', '/inflection'];
    const sum = (a: number, b: number) => a + b;
    //@ts-ignore
    const hidden = hidingRoutes.map(r => location.pathname === r).reduce(sum) > 0;

    return (hidden ? <></> :
        <div className="flex flex-wrap justify-center text-xs">
            <div className={grayCapsuleStyle}>
                <HInput
                    label="Duration"
                    unit="(sec)"
                    placeholder={duration}
                    onChange={setDuration}
                    value={duration}
                />
                <HInput
                    label="Points"
                    unit="(n)"
                    placeholder={n}
                    onChange={setN}
                    value={n} />
            </div>
            <div className={grayCapsuleStyle}>
                <HCheckbox label="Palv" isOn={showPalv} toggle={togglePalv} />
                <HCheckbox label="Pmus" isOn={showPmus} toggle={togglePmus} />
                <HCheckbox label="Bold" isOn={thickLines} toggle={toggleThickLines} />
            </div>
            <div className={grayCapsuleStyle}>
                <HCheckbox label="Auto Scale" isOn={autoScale} toggle={toggleAutoScale} />
                <HInput disabled={autoScale} label="P" placeholder={pMax} value={pMax} onChange={setpMax} />
                <HInput disabled={autoScale} label="F" placeholder={fMax} value={fMax} onChange={setfMax} />
                <HInput disabled={autoScale} label="V" placeholder={vMax} value={vMax} onChange={setvMax} />
            </div>
        </div>
    );
}